<template>
  <li>
    <p v-if="!isVisitSelected"
       class='selectVisitButton select'
       @click.stop="selectVisitForReport()">
      {{ x('select') }}
    </p>
    <p v-else class='Theme_C4_BG Theme_C4_Border selectVisitButton'
       @click.stop="deselectVisitForReport()">
      {{ x('deselect') }}
    </p>
  </li>
</template>

<script>
  import { mapState } from 'vuex';
  import translate from '@/components/Mixins/Translate';

  /**
   * This holds the section of a card that has a button for selecting the visit for a report.
   */
  export default {
    name: 'select-for-report',
    mixins: [translate],
    props: ['item'],
    data() {
      return {
        isVisitSelected: false,
      };
    },
    computed: {
      ...mapState('ReportStore', ['selectedForReport', 'reportUIVisible']),
    },
    watch: {
      selectedForReport(newValue) {
        this.isVisitSelected = (this.item.id in newValue);
      },
    },
    methods: {
      selectVisitForReport() {
        console.log(`selecting visit: ${this.item.id}`);
        this.$store.commit('ReportStore/selectVisitForReport', this.item);
      },
      deselectVisitForReport() {
        this.$store.commit('ReportStore/deselectVisitForReport', this.item.id);
      },
    },
  };
</script>

<style scoped>

  .selectVisitButton {
    width: 80%;
    display: inline-block;
    text-align: center;
    padding: 4px 0;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 900;
    /*bottom: -30px;*/
    position: relative;
    left: 10%;
    border-radius: 6px;
    cursor: pointer;
    margin: 5px auto;
    border-bottom: 2px solid #d35400;
  }

  .select {
    background: #e67e22;
  }

</style>
