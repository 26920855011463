<template>
  <a @click="showItem()" class="feed Theme_C2_BG Theme_C2_Border" :id="item.id">
    <ul class="">
      <li class="image minHeight70">
        <img :src="imageSource"
             :class="{ minHeight200: imageLoadFailed }"
             @error="onImageLoadError"
             @load="onImageLoaded"
             alt="">
        <transition name="fade">
          <span v-if="showLoadCover" class='loadCover'>
            <icon v-if="imageLoadFailed" name='fa-unlink'/>
            <icon v-else name='fa-cog fa-spin'/>
          </span>
        </transition>
        <span v-if="config.features[featureNames.bookmarks]" @click.stop="bookmarkItem()"
              class="bookmark_button" :class="{ hasBookmarked: bookmarks.length > 0 }">
          <icon :name="config.obj_texts.icon_bookmark"/>
        </span>
        <span v-if="config.features[featureNames.likes]" @click.stop="likeItem()"
              class="like_button" :class="{ hasLiked: item.Liked }" :id="'bLike_'+item.id">
          <icon :name="likeClass"/>
        </span>
      </li>

      <li v-if="downloadUIVisible" class="adminbar admin_image">
        <span v-for="(picture, index) in item.pictures" :style="pictureBackgroundStyle(index)"
              :key="index" class="selectable-image" @click="changeImage(index)">
          <p v-if="!isPictureSelected(picture.name)"
             class='Theme_C3_BG Theme_C3_Border selectButton'
             @click.stop="selectPictureForDownload(index)">
            {{ x('select') }}
          </p>
          <p v-else class='Theme_C4_BG Theme_C4_Border selectButton deselect'
             @click.stop="deselectPictureForDownload(index)">
            <icon name="fa-close"/>
          </p>
        </span>
        <div class="lsc-clear"></div>
      </li>

      <li class="text ThemeFeedItem" :class="scoreClass">
        <span class="reaction"><i class="fa" :class="reactionIcon"/></span>
        <h2>{{ cardName }}</h2>
        <span class="visitTime">{{ visitDate }}</span>
        <span class='ziptown'>{{ zip }} {{ town }}</span>
        <p class="customer-extra" v-if="custExtra1 !== ''">{{ custExtra1 }}</p>

        <span v-if="item.reactionType!=='idea'
        && item.reactionType!=='survey'
        && user.userRole==='internal'" class="type">
          <h3>{{ x('type') }}</h3>
          <span v-for="campaign in item.campaigns" class='lsc-rounded blob ThemeFeedItemBubble'
                :key="campaign.id">
            {{ campaign.name }}
          </span>
        </span>

        <p class="comment">{{ item.comment }}</p>
      </li>

      <li class="numbers ThemeFeedItem" :class="scoreClass">

        <span class="small"><icon name="fa-user"/>{{ username }}</span>
        <span class="small"><icon name="fa-calendar"/>{{ visitTime }}</span>
        <br>

        <span v-if="config.features[featureNames.comments]" class="small">
          <icon name="fa-commenting"/>
          <span class="comment_count">{{ item.countComments }}</span>
        </span>

        <span v-if="config.features[featureNames.likes]" class="small">
          <icon name="fa-thumbs-up"/>
          <span class="like_count">{{ item.countLikes }}</span>
        </span>
        <span v-if="user.userRole==='external'" class="small userRoleIco">A</span>
      </li>

      <select-for-report v-if="reportUIVisible" :item="item"/>
    </ul>
  </a>
</template>

<script>
  import { defineAsyncComponent, markRaw } from 'vue';
  import moment from 'moment';
  import { mapState } from 'vuex';
  import { httpPost, httpDelete } from '@/classes/httpHelper';
  import SelectForReport from '@/components/Pages/SelectForReport';
  import scoreNames from '@/enums/score-names';
  import featureNames from '../../enums/feature-names';
  import translate from '../Mixins/Translate';

  const ItemDetails = defineAsyncComponent(() => import('./ItemDetails'));

  export default {
    name: 'feed-item',
    props: {
      model: {
        type: Object,
        required: true,
      },
    },
    mixins: [translate],
    components: {
      'select-for-report': SelectForReport,
    },
    data() {
      return {
        item: this.model,
        showLoadCover: true,
        imageLoadFailed: false,
        currentImageIndex: 0,
        didLoadImages: false,
        likeInProgress: false,
        likeClass: 'fa-thumbs-up',
        selectedImages: [],
        featureNames,
        imageSource: '//:0',
      };
    },
    computed: {
      username() {
        return `${this.item.user[0].firstName} ${this.item.user[0].lastName}`;
      },
      visitTime() {
        return moment.utc(this.item.visitTime).local().format('D MMM YYYY - HH:mm');
      },
      visitDate() {
        return moment.utc(this.item.visitTime).local().format('D MMM YYYY');
      },
      cardName() {
        if (this.item.reactionType === 'idea') return this.item.title;
        return this.item.customer ? this.item.customer[0].name : '';
      },
      zip() {
        if (this.item.reactionType === 'idea') return '';
        return this.item.customer ? this.item.customer[0].postalCode : '';
      },
      town() {
        if (this.item.reactionType === 'idea') return '';
        return this.item.customer ? this.item.customer[0].city : '';
      },
      custExtra1() {
        const extra1 = this.item.customer ? this.item.customer[0].extra1 : null;

        if (extra1 !== null && extra1 !== undefined && extra1 !== '') {
          return extra1;
        }
        return '';
      },
      reactionIcon() {
        if (!this.config.obj_texts) return '';
        switch (this.item.reactionType) {
          case 'rfi':
            return this.config.obj_texts.IconSmall_rfi || 'fa-frown-o';
          case 'idea':
            return this.config.obj_texts.IconSmall_idea || 'fa-lightbulb-o';
          case 'survey':
            return this.config.obj_texts.Icon_survey || 'fa-question-circle';
          default:
            return this.config.obj_texts.IconSmall_like || 'fa-smile-o';
        }
      },
      dataFilterList() {
        if (!this.item.campaigns) return '';
        return this.item.campaigns.map((campaign) => `${campaign.name},`);
      },
      thumbBaseUrl() {
        return this.config.thumbBaseUrl;
      },
      adminImagesClass() {
        if (this.item.pictures === undefined) return 'single';
        return this.item.pictures.length > 1 ? 'multiple' : 'single';
      },
      bookmarks() {
        if (!this.config.features[featureNames.bookmarks]) return [];
        if (!this.item.bookmarks) return [];
        return this.item.bookmarks.split(',');
      },
      scoreClass() {
        if (this.config.features[featureNames.visitScore] === true) {
          switch (this.item.score) {
            case scoreNames.green: return 'green-card';
            case scoreNames.yellow: return 'yellow-card';
            case scoreNames.red: return 'red-card';
            default: return '';
          }
        }
        return '';
      },
      ...mapState(['config', 'user']),
      ...mapState('DownloadStore', ['downloadUIVisible', 'selectedForDownload']),
      ...mapState('ReportStore', ['reportUIVisible']),
    },
    watch: {
      model: {
        deep: true,
        handler(newValue) {
          this.item = newValue;
        },
      },
      thumbBaseUrl() {
        this.loadCoverImage();
      },
      selectedForDownload(newValue) {
        if (this.item.id in newValue) {
          this.selectedImages = newValue[this.item.id];
        } else if (this.selectedImages.length > 0) {
          this.selectedImages = [];
        }
      },
      async bookmarks() {
        this.didLoadImages = false;
        await this.loadCoverImage();
      },
    },
    async mounted() {
      await this.loadCoverImage();
    },
    methods: {
      pictureBackgroundStyle(index) {
        return `background:url("${this.config.thumbBaseUrl}${this.item.pictures[index].name}");`;
      },
      deselectPictureForDownload(index) {
        this.$store.commit('DownloadStore/deselectImageForDownload', {
          visitId: this.item.id,
          imageName: this.item.pictures[index].name,
        });
      },
      selectPictureForDownload(index) {
        this.$store.commit('DownloadStore/selectImageForDownload', {
          visitId: this.item.id,
          imageName: this.item.pictures[index].name,
        });
      },
      isPictureSelected(pictureName) {
        return this.selectedImages.includes(pictureName);
      },
      changeImage(index) {
        this.currentImageIndex = index;
      },
      showItem() {
        console.debug('Loading an item with id ', this.item.id);
        this.$store.commit('pushPopup', {
          component: markRaw(ItemDetails),
          title: this.x('visit'),
          showSuffix: true,
          params: {
            id: this.item.id,
          },
          direction: 'left',
        });
      },
      async likeItem() {
        // Skipping like because another is already in progress. Probably this is a double-click.
        if (this.likeInProgress === true) return;
        this.likeInProgress = true;

        this.likeClass = 'fa-cog fa-spin';
        await this.$nextTick();

        if (this.item.Liked === false
          || this.item.Liked === undefined
          || this.item.Liked === null) {
          // liking
          await httpPost(`visits/${this.item.id}/likes`, {});
          this.item.Liked = true;
        } else {
          await httpDelete(`visits/${this.item.id}/likes`);
          this.item.Liked = false;
        }

        this.likeClass = 'fa-thumbs-up';
        this.likeInProgress = false;
      },
      async bookmarkItem() {
        if (this.bookmarks.length === 0) {
          await httpPost(`visits/${this.item.id}/bookmark`, { bookmarkData: '0' });
          // TODO: TEST VUE3
          // this.$set(this.item, 'bookmarks', '0');
          this.item.bookmarks = '0';
        } else {
          await httpDelete(`visits/${this.item.id}/bookmark`);
          // TODO: TEST VUE3
          this.item.bookmarks = '';
          // this.$set(this.item, 'bookmarks', '');
        }
      },
      onImageLoadError() {
        console.warn(`Image failed to load: '${this.imageSource}'`);
        this.imageLoadFailed = true;
      },
      onImageLoaded() {
        this.showLoadCover = false;
      },
      async loadCoverImage() {
        // Some times this component is loaded before config. In that case, we need to wait
        // with image loading until config has loaded. That is done by watching thumbBaseUrl
        // computed property, which gets updated from vuexStore when config is ready.
        if (this.didLoadImages) return;
        if (this.config.thumbBaseUrl === undefined) return;
        this.didLoadImages = true;

        if (this.config.features[featureNames.bookmarks] && this.bookmarks.length > 0) {
          const index = parseInt(this.bookmarks[0], 10);
          this.imageSource = this.config.thumbBaseUrl + this.item.pictures[index].name;
        } else {
          this.imageSource = this.config.thumbBaseUrl + this.item.firstPictureName;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
 .minHeight200 {
    min-height: 200px;
  }

  .minHeight70 {
    min-height: 70px;
  }

  .admin_image {
    background: #f0f0f0;
    padding: 4px 2px 2px 2px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    margin-bottom: 0;
  }

  .admin_image .selectable-image {
    width: 33.3333333%; /*calc(33% - 2px);*/
    height: 80px;
    display: inline-block;
    border: 2px solid #f0f0f0;
    background-position: center center !important;
    background-size: cover !important;
    float: left;
    position: relative;
    color: #ffffff;
    margin-bottom: 35px;
  }

  .selectButton {
    width: 80%;
    display: inline-block;
    text-align: center;
    background: #e67e22;
    padding: 4px 0;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 900;
    bottom: -30px;
    position: absolute;
    left: 10%;
    border-radius: 6px;
    border-bottom: 2px solid #d35400;
    cursor: pointer;

    &.deselect {
      font-size: 14px;
      padding: 2px 0;
    }
  }

  .white {
    background-color: white !important;
  }

  .green-card {
    background: var(--ThemeVisitScoreGreen) !important;
  }

  .yellow-card {
    background: var(--ThemeVisitScoreYellow) !important;
  }

  .red-card {
    background: var(--ThemeVisitScoreRed) !important;
  }

  .customer-extra {
    margin: 10px 0;
    white-space: pre-line;
  }

  .comment {
    font-style: italic;
    margin-top: 6px;
    margin-bottom: 3px;
  }

  // Transitions: Fade
 .fade-enter-active, .fade-leave-active {
   transition: opacity .5s;
 }
 .fade-enter, .fade-leave-to {
   opacity: 0;
 }

</style>
